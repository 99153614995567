import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../AuthContext';
import { AccountStoreContext } from '../store/AccountStore.js';
import { observer } from 'mobx-react-lite';
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import Header from '../Header/Header.js';
import LeftSection from '../LeftSection/LeftSection.js';
import PreviewSection from '../PreviewSection/PreviewSection.js';
import RightSection from '../RightSection/RightSection.js';
import ProfileSection from '../ProfileSection/ProfileSection.js';

const Dashboard = observer(() => {
  const user = useContext(AuthContext);
  const accountStore = useContext(AccountStoreContext);

  useEffect(() => {
    const fetchAccounts = async () => {
      const db = getFirestore();
      const q = query(collection(db, "accounts"), where("displayName", "==", user?.displayName));
      const querySnapshot = await getDocs(q);
      let accounts = [];
      querySnapshot.forEach((doc) => {
        accounts.push(doc.data());
      });
      accountStore.setAccounts(accounts);
    };
    if (user) {
      fetchAccounts();
    }
  }, [accountStore, user]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <style>
        {`
          ::-webkit-scrollbar {
            width: 0;
            height: 0;
          }
        `}
      </style>
      <div style={{ display: 'flex', width: '100%', height: 56, background: '#9CAFAA', alignItems: 'center' }}>
        <Header />
      </div>
      {!accountStore.showProfile ? (
      <div style={{ display: 'flex', justifyContent: 'center', height: 'calc(100vh - 56px)', overflowX: 'hidden' }}>
        <LeftSection />
        <PreviewSection />
        <RightSection/>
      </div>
      ) : (
      <div style={{ display: 'flex', justifyContent: 'center', height: 'calc(100vh - 56px)', overflowX: 'hidden' }}>
        <ProfileSection />       
      </div> 
      )}
    </div>
  );
})
export default Dashboard;