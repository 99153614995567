import React, { useContext, useState } from 'react';
import styles from './card.module.css';
import { observer } from 'mobx-react-lite';
import { AccountStoreContext } from '../store/AccountStore';
import SkeletonLoaderDesktop from '../SkeletonLoaderDesktop/SkeletonLoaderDesktop';
import SkeletonLoaderTitleDesktop from '../SkeletonLoaderTitleDesktop/SkeletonLoaderTitleDesktop';
import SkeletonLoaderArticleDesktop from '../SkeletonLoaderArticleDesktop/SkeletonLoaderArticleDesktop';
import SkeletonLoaderReadmoreDesktop from '../SkeletonLoaderReadmoreDesktop/SkeletonLoaderReadmoreDesktop';
import SkeletonLoaderWordCountDesktop from '../SkeletonLoaderWordCountDesktop/SkeletonLoaderWordCountDesktop';

const CardSection = observer(({ title, text, hashtags = [], wordCount, image }) => {
  const [isOpen, setIsOpen] = useState(false);
  const accountStore = useContext(AccountStoreContext);

  const countWords = (str) => {
    return str.split(/\s+/).filter(word => word.length > 0).length;
  };

  const words = countWords(text);

  const handleClick = () => {
    if (words <= 50) return; 
    setIsOpen(!isOpen);
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length <= wordLimit) {
      return text;
    }
    return words.slice(0, wordLimit).join(' ') + '...';
  };

  const formatText = (text) => {
    const regex = /<br>(.*?)<\/br>/g;
    const newText = text.replace(regex, '<strong>$1</strong>');
    const truncatedText = truncateText(newText, 50);
    return { __html: isOpen ? newText : truncatedText };
  };

  const backlinkClick = () => {
    const backlink = accountStore.backlink;

    if (backlink.startsWith('http://') || backlink.startsWith('https://')) {
      window.open(backlink, '_blank');
    } else {
      window.open(`https://${backlink}`, '_blank');
    }
  };

  return (
    <div className={styles.card}>
      <div style={{ position: 'relative' }}>
        <img src={image} alt="card" className="card-image" style={{ padding: 10, borderRadius: 20, height: 200, width: 200, cursor: 'pointer' }} onClick={backlinkClick} />
        {accountStore.generatingContent === true ? <SkeletonLoaderDesktop /> : ""}
      </div>
      <div className={styles.cardContent} style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div className='article'>
          <div style={{ position: 'relative' }}>
            {accountStore.generatingContent === true ? "" : <h2 className={styles.cardTitle} style={{ fontSize: 24, width: '95%' }}>{title}</h2>}
            {accountStore.generatingContent === true ? <SkeletonLoaderTitleDesktop /> : ""}
          </div>
          {accountStore.generatingContent === true ? "" : <p className={styles.cardText} style={{ fontSize: 18, width: '95%', marginBottom: 0 }} dangerouslySetInnerHTML={formatText(text)}></p>}
          {accountStore.generatingContent === true ? <SkeletonLoaderArticleDesktop /> : ""}
        </div>
        <div className='elements' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10, justifyContent: 'space-between', marginRight: 10 }}>
          {accountStore.generatingContent === true ? "" : <div className={styles.cardHashtags}>{hashtags && hashtags.length > 0 && hashtags.map(hashtag => <span key={hashtag} style={{ position: 'relative', background: '#F4F8F9', padding: 10, borderRadius: 20, margin: 5, fontWeight: 'bold', height: 10, fontSize: 12, display: 'inline-block', flexWrap: 'wrap' }}>{hashtag}</span>)}</div>}
          {accountStore.generatingContent === true ? "" : <p className={styles.cardWordCount} style={{ bottom: 7, fontSize: 14 }}>Wordcount: {wordCount}</p>}
          {accountStore.generatingContent === true ? "" : <div className={styles.cardButton} style={{ backgroundColor: '#4C6FFF', width: 100, height: 34, borderRadius: 8, fontSize: 12, color: 'white', textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex', margin: 0, fontFamily: 'nexa_boldregular', cursor: words > 50 ? 'pointer' : 'not-allowed', opacity: words > 50 ? 1 : 0.5 }} onClick={handleClick}>{isOpen ? 'Read Less' : 'Read More'}</div>}
          {accountStore.generatingContent === true ? <SkeletonLoaderReadmoreDesktop /> : ""}
          {accountStore.generatingContent === true ? <SkeletonLoaderWordCountDesktop /> : ""}
        </div>
      </div>
    </div>
  );
});
export default CardSection;