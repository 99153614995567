import React, { useContext, useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { AccountStoreContext } from '../store/AccountStore';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import PlanDropdown from '../PlanDropdown/PlanDropdown';
import TimeDropdown from '../TimeDropdown/TimeDropdown';
import CalendarComponent from '../CalendarComponent/CalendarComponent';

let currentAccount;

const PostMenu = observer(({ items }) => {
  const accountStore = useContext(AccountStoreContext);
  const [planDropdown, setPlanDropdown] = useState(true);
  const [timeDropdown, setTimeDropdown] = useState(true);
  const [calendarDropdown, setCalendarDropdown] = useState(true);
  const [, setSelectedAccount] = useState(null);
  const [, setSelectedAccountData] = useState(null);

  const fetchSelectedAccount = useCallback(async () => {
    try {
      const db = getFirestore();
      const q = query(collection(db, "accounts"), where("accountName", "==", currentAccount));
      const querySnapshot = await getDocs(q);
      let selectedAccount = {};
      querySnapshot.forEach((doc) => {
        selectedAccount = { id: doc.id, ...doc.data() };
      });
      setSelectedAccountData(selectedAccount);
      console.log('selectedAccount', selectedAccount);
      accountStore.setKeyword1(selectedAccount.keyword1);
      accountStore.setKeyword2(selectedAccount.keyword2);
      accountStore.setKeyword3(selectedAccount.keyword3);
      accountStore.setAccountName(selectedAccount.accountName);
      accountStore.setStartDate(selectedAccount.startDate);
      accountStore.setFrequency(selectedAccount.daily);
      accountStore.setBackLink(selectedAccount.backlink);
      accountStore.setHashTags(selectedAccount.hashtags);
      accountStore.setTone(selectedAccount.tone);
      accountStore.setDaily(selectedAccount.daily);
      accountStore.setStartTime(selectedAccount.startTime);
      accountStore.setOpenAIKey(selectedAccount.openAIKey);
      accountStore.setAYRKey(selectedAccount.ayrKey);
      accountStore.setRunning(selectedAccount.running);
      accountStore.setTwitter(selectedAccount.twitter);
      accountStore.setInstagram(selectedAccount.instagram);
      accountStore.setFacebook(selectedAccount.facebook);
      accountStore.setLinkedIn(selectedAccount.linkedIn);
      accountStore.setBlog(selectedAccount.blog);
      accountStore.setWebsite(selectedAccount.website);
      accountStore.setEmail(selectedAccount.email);
      accountStore.setPhone(selectedAccount.phone);
      accountStore.setWordCount(selectedAccount.wordCount);
      accountStore.setProfileImage(selectedAccount.profileImage)
    } catch (error) {
      console.error("Error fetching account data: ", error);
      setSelectedAccount("");
    }
  }, [accountStore]);

  useEffect(() => {
    if (accountStore.selectedAccount) {
      fetchSelectedAccount();
    }
  }, [accountStore.selectedAccount, fetchSelectedAccount]);
  
  const togglePlanDropdown = (index) => {
    if(index === 0){
      setPlanDropdown(!planDropdown);
    } else if(index === 1){
      setTimeDropdown(!timeDropdown);
    } else {
      setCalendarDropdown(!calendarDropdown);
    }
    
  };

  const renderedItems = items.map((item, index) => {
    // const activePlanDropdown = index === activeIndex ? 'block' : 'none';
    return (
      <div key={item.title} style={{ background: 'rgba(0,0,0,0.0001)' }}>
        <div onClick={() => togglePlanDropdown(index)} style={{ background: 'rgba(0,0,0,0.0001)', boxShadow: 'inset 0px -1px 0px rgba(0,0,0,0.1)', position: 'relative', textDecoration: 'none', listStyle: 'none', width: '100%', display: 'flex', height: 40, borderTop: '0px solid black' }}>
          <div style={{ display: 'flex', width: 'calc(100% - 40px)', position: 'relative', alignItems: 'center', paddingLeft: 10, paddingRight: 10, cursor: 'pointer', fontFamily: 'nexa_boldregular', fontWeight: 'bold', fontSize: 11 }}>
            <img src={item.image} alt="" style={{ marginRight: 10 }} />
              Select {index === 0 ? "Day" : ""}
              {index === 1 ? "Time" : ""}
              {index === 2 ? "Plan" : ""}
            <span style={{ position: 'absolute', right: 0, transition: 'transform 0.3s ease',
              transform: (planDropdown === true && index === 0) || 
                (timeDropdown === true && index === 1) || 
                (calendarDropdown === true && index === 2)  
                ? 'rotate(180deg)' 
                : 'rotate(0deg)' }}>
              <img src="./down-arrow.svg" alt="" />
            </span>
          </div>
        </div>
        { planDropdown === true && index === 0 ? <CalendarComponent /> : "" }
        { timeDropdown === true && index === 1 ? <TimeDropdown /> : "" }
        { calendarDropdown === true && index === 2 ? <PlanDropdown /> : "" }
        {/* <div stye>
          {index === 0 ? <PlanDropdown /> : ""}
          {index === 1 ? <TimeDropdown /> : ""}
          {index === 2 ? <CalendarComponent /> : ""}
        </div> */}
      </div>
    );
  });
  return <div style={{ height: 'calc(100% - 110px)', overflowY: 'auto' }}>{renderedItems}</div>;
});
export default PostMenu;