import React, { useContext, useEffect, useState, useCallback } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { observer } from 'mobx-react';
import { AccountStoreContext } from '../store/AccountStore.js'; 
import { format, endOfMonth, eachDayOfInterval, startOfMonth, differenceInCalendarDays } from 'date-fns';

let newDate;

const CalendarComponent = observer(({ frequency }) => {
  const accountStore = useContext(AccountStoreContext);
  let initialDateValue = accountStore.startDate ? new Date(accountStore.startDate) : new Date();
  const [date, setDate] = useState(new Date());
  const [, setHighlightDates] = useState([]);
  const [postMessage, setPostMessage] = useState("");
  const [initialDate, setInitialDate] = useState(initialDateValue);

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  if (!isValidDate(initialDateValue)) {
    initialDateValue = new Date();
  }

  const setNewDate = useCallback(() => {
    accountStore.setStartDate(newDate);
    setDate(new Date(newDate));
  }, [accountStore]);

  useEffect(()=>{
    if(accountStore.startDate) {
      const parsedDate = Date.parse(accountStore.startDate);
      if (!isNaN(parsedDate)) {
        newDate = accountStore.startDate;
        setNewDate();
      } else {
        console.error(`Invalid date format: ${accountStore.startDate}`);
      }
    }
  },[accountStore.startDate, setNewDate]);

  const updateDates = useCallback((startDate) => {
    let currentDate = startOfMonth(startDate);
    let endDate = endOfMonth(startDate);
    let allDays;
    let dates = [];

    try {
      allDays = eachDayOfInterval({ start: currentDate, end: endDate });
    } catch (e) {
      return;
    }

    for (let i = 0; i < allDays.length; i++) {
      switch (frequency) {
        case 'daily':
          break;
        case 'weekly':
          if (differenceInCalendarDays(allDays[i], initialDate) % 7 === 0) dates.push(format(allDays[i], 'MM/dd/yyyy'));
          break;
        case 'biweekly':
          if (differenceInCalendarDays(allDays[i], initialDate) % 14 === 0) dates.push(format(allDays[i], 'MM/dd/yyyy'));
          break;
        case 'monthly':
          if (differenceInCalendarDays(allDays[i], initialDate) % 30 === 0) dates.push(format(allDays[i], 'MM/dd/yyyy'));
          break;
        default:
          break;
      }
    }

    setHighlightDates(dates);

    if (frequency === 'daily') {
      setPostMessage("Posts will be made every day of this month.");
    } else {
      setPostMessage(`Posts will be made on the following dates: ${dates.join(', ')}`);
    }
  }, [frequency, initialDate]);

  useEffect(() => {
    updateDates(date);
  }, [date, frequency, updateDates]);

  const handleDateChange = (date) => {
    if (date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date)) {
        setDate(date);
        if (!initialDate) setInitialDate(date);
        accountStore.setStartDate(format(date, 'yyyy-MM-dd'));
    } else {
        setDate(new Date());
    }
  };

  return (
    <div className="App">
      <Calendar onChange={handleDateChange} value={date} onActiveStartDateChange={({ activeStartDate }) => updateDates(activeStartDate)}/>
      <p style={{ fontSize: 12 }}>
        {postMessage}
      </p>
    </div>
  );
});
export default CalendarComponent;