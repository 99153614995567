import React, { useState, useContext } from 'react';
import { observer } from 'mobx-react';
import { AccountStoreContext } from '../store/AccountStore';

const SocialSection = ({ items }) => {
  const [, setActiveIndex] = useState(null);
  const accountStore = useContext(AccountStoreContext);

  const formatText = (text) => {
    const regex = /<b>(.*?)<\/b>/g;
    const newText = text.replace(regex, '<strong>$1</strong>');
    return { __html: newText } ;
  };

  const onTitleClick = (index, isEnabled) => {
    if (isEnabled) {
      setActiveIndex(index);
    }
  };

  const isSocialMediaEnabled = (title) => {
    switch (title) {
      case 'Facebook':
        return accountStore.facebook;
      case 'Instagram':
        return accountStore.instagram;
      case 'LinkedIn':
        return accountStore.linkedIn;
      case 'Twitter':
        return accountStore.twitter;
      case 'Blog':
        return accountStore.blog;
      default:
        return false;
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ display: "flex", flexDirection: "column", width: '25%' }}>
        {items.map((item, index) => {
          const isEnabled = isSocialMediaEnabled(item.title);

          return (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ height: 50, width: '100%', background: '#FFFFFF', borderTopLeftRadius: 20, borderTopRightRadius: 20, display: 'flex', position: 'relative', flexDirection: 'column', padding: 0, justifyContent: 'center', borderBottomLeftRadius: 20, borderBottomRightRadius: 20, marginBottom: 20, opacity: isEnabled ? '' : 0.8, cursor: isEnabled ? 'pointer' : 'not-allowed' }}
                onClick={() => onTitleClick(index, isEnabled)}>
                <div style={{ display: 'flex', width: 'calc(100% - 40px)', position: 'relative', alignItems: 'center', paddingLeft: 10, paddingRight: 10, fontFamily: 'nexa_boldregular', color: isEnabled ? '#000' : '#A0A0A0' }}>
                  <img src={item.social} style={{ marginRight: 10, filter: isEnabled ? 'none' : 'grayscale(100%)' }} alt="" />
                  {item.title}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div style={{ width: '75%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 32, marginRight: 32, marginBottom: 32, backgroundColor: '#FFFFFF' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, fontFamily: 'nexa_boldregular', width: '100%' }}>
          Social Media Preview
        </div>
        <h2 style={{ fontSize: 24, marginBottom: 8, margin: 0 }}>
        {accountStore.cards[0].title}
        </h2>
        <img src={accountStore.cards[0].image} alt="" style={{ padding: 10, borderRadius: 20, height: 184, width: 184 }} />
        <p dangerouslySetInnerHTML={formatText(accountStore.cards[0].text)} style={{ marginTop: 4, marginBottom: 4, paddingLeft: 8, paddingRight: 8 }} />
        {accountStore.cards[0].hashtags}
      </div> 
    </div>
  );
};
export default observer(SocialSection);