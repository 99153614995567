import React, { useState, useContext, useEffect } from 'react';
import { AccountStoreContext } from '../store/AccountStore';

const TimeDropdown = () => {
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [period, setPeriod] = useState("AM");
  const [timezone, setTimezone] = useState("PST");
  const accountStore = useContext(AccountStoreContext);
  const [buttonText, setButtonText] = useState("Save");

  useEffect(() => {
    if (accountStore.startTime) {
      const timeParts = accountStore.startTime.split(" ");
      if (timeParts.length === 3) {
        const [h, m] = timeParts[0].split(":");
        setHours(h);
        setMinutes(m);
        setPeriod(timeParts[1]);
        setTimezone(timeParts[2]);
      }
    }
  }, [accountStore.startTime]);

  const handleHoursChange = (event) => {
    setHours(event.target.value);
  }

  const handleMinutesChange = (event) => {
    setMinutes(event.target.value);
  }

  const handleTimezoneChange = (event) => {
    setTimezone(event.target.value);
  }

  const saveTime = () => {
    if (hours >= 1 && hours <= 12 && minutes >= 0 && minutes <= 59) {
      const validMinutes = minutes < 10 ? `0${minutes}` : minutes;
      accountStore.setStartTime(`${hours}:${validMinutes} ${period} ${timezone}`);
      setButtonText("Saved");
      setTimeout(() => {
        setButtonText("Save");
      }, 3000);
    }
  }

  const pillStyle = {
    display: "inline-flex",
    width: "60px",
    justifyContent: "space-around",
    borderRadius: "20px",
    backgroundColor: "white",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    marginLeft: 10,
    marginRight: 10,
    height: 30,
    overflow:'hidden'
  };
  
  const buttonStyle = selected => ({
    backgroundColor: selected ? "rgb(76, 111, 255)" : "white",
    color: selected ? "white" : "black",
    border: "none",
    borderRadius: "10px",
    width: "30px",
    fontSize: 10,
  });

  return (
    <div style={{ padding:20,  boxShadow: 'rgba(0, 0, 0, 0.1) 0px -1px 0px inset'}}>
      <input id="hours" type="text" value={hours} onChange={handleHoursChange} style={{ border: 'none', height: 25, width: '47px', background: 'rgb(245, 245, 245)', borderRadius: 10, fontSize: 10, textAlign: 'center', marginRight: 10 }}/>
      :
      <input id="minutes" type="text" value={minutes} onChange={handleMinutesChange} style={{ border: 'none', height: 25, width: '47px', background: 'rgb(245, 245, 245)', borderRadius: 10, fontSize: 10, textAlign: 'center', marginLeft: 10 }}/>
      <div style={pillStyle}>
        <button onClick={() => setPeriod("AM")} style={buttonStyle(period === "AM")}>
          AM
        </button>
        <button onClick={() => setPeriod("PM")} style={buttonStyle(period === "PM")}>
          PM
        </button>
      </div>
      <input id="timezone" type="text" value={timezone} onChange={handleTimezoneChange} style={{ width: '2em', background: '#F5F5F5', border: 'none', borderRadius: 10, height: 25, textAlign: 'center' }}/>
      <button onClick={saveTime} style={{ backgroundColor: 'rgb(76, 111, 255)', width: 55, height: 25, borderRadius: 8, fontSize: 12, color: 'white', textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex', bottom: '7px', border: 'none', marginTop: 10 }}>
        {buttonText}
      </button>
    </div>
  );
}
export default TimeDropdown;