import { observer } from 'mobx-react';
import React, { useState } from 'react';
import PostMenu from '../PostMenu/PostMenu.js';

const items = [
  {
    title: 'Title 1',
    content: 'Content 1',
    image: './calendar.svg'
  },
  {
    title: 'Title 2',
    content: 'Content 2',
    image: './clock.svg'
  },
  {
    title: 'Title 3',
    content: 'Content 3',
    image: './frequency.svg'
  },
];

const RightSection = observer(() => {
  const [reRenderPostMenu, setReRenderPostMenu] = useState(true);
  const [frequency, setFrequency] = useState('daily');

  return(
    <div id="right-menu-desktop-wrap" style={{ width: '100%', maxWidth: 288, height: 'calc(100vh - 56px)', position: 'fixed', boxShadow: '0px 0px 32px rgba(0,0,0,0.1)', background: '#FFFFFF', marginLeft: 20, top: 56, right: 0, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
      <p style={{ fontSize: 33, fontWeight: 'bold', position: 'relative', fontFamily: 'nexa_boldregular', margin: 8, height: 38 }}>
        Post
      </p>
      {reRenderPostMenu ? <PostMenu items={items} frequency={frequency} setFrequency={setFrequency} reloadMenu={() => {
          setReRenderPostMenu(false);
          setReRenderPostMenu(true);
        }} /> : <PostMenu items={items} frequency={frequency} setFrequency={setFrequency} reloadMenu={() => {
          setReRenderPostMenu(false);
          setReRenderPostMenu(true);
        }} />
      }
      <div style={{ width: '100%', background: 'white', height: 56, display: 'flex', alignItems: 'center', justifyContent: 'end', boxShadow: '0px -1px 0px rgba(0, 0, 0, 0.1)' }}>
        <img src="./ding-data.svg" alt="" style={{ padding: 10 }} />
      </div>
    </div>
  );
})
export default RightSection;